import { gql } from 'apollo-boost';
import { EmptyResponseError } from './errors/empty-response-error';
import { getAuthenticatedGraphQLClientByToken } from './graphql-client';
import { SharedLink } from './models/shared-link';

export const getSharedLink = async (storyId: number, token: string): Promise<SharedLink> => {
  const client = getAuthenticatedGraphQLClientByToken(token);

  const response = await client.query<{ sharedLink: SharedLink }>({
    query: _getSharedLink(),
    variables: {
      sharedLinkId: storyId,
    },
  });

  if (!response.data) {
    throw new EmptyResponseError();
  }

  return response.data.sharedLink;
};

const _getSharedLink = () => {
  return gql`
    query getSharedLink($sharedLinkId: Long) {
      sharedLink(sharedLinkId: $sharedLinkId) {
        shareCount
        story {
          id
          author
          excerpt
          imageHeight
          imageUrl
          imageWidth
          language
          publicationDate
          source
          title
          url
          isClicked
        }
        tweets {
          body
          bodyHtml
          dateCreated
          externalStatusId
          externalUrl
          imageUrl
          name
          username
          time
        }
        externalUsers {
          bannerImageUrl
          description
          externalUserid
          imageUrl
          name
          type
          url
          username
        }
      }
    }
  `;
};
