import { Intent, Spinner } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import 'normalize.css/normalize.css';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { loginVerification } from '../../services/authentication';
import Reader from '../reader/Reader';
import './App.scss';

interface ReaderEvent {
  storyId: number;
  storyUri: string;
  token: string;
}

enum Mode {
  Initialized,
  Configured,
  Loading,
  ContentReady,
}

const App: FunctionComponent = () => {
  const [uri, setUri] = useState('');
  const [token, setToken] = useState('');
  const [storyId, setStoryId] = useState(-1);
  const [mode, setMode] = useState<Mode>(Mode.Initialized);

  const fetchSession = async (refreshToken: string) => {
    const session = await loginVerification(refreshToken);

    setToken(session.token);
    setMode(Mode.ContentReady);
  };

  useEffect(() => {
    const handler = (event: MessageEvent<ReaderEvent>) => {
      if (!event.data.token) {
        return;
      }

      setUri(event.data.storyUri);
      setStoryId(event.data.storyId);
      setMode(Mode.Configured);

      fetchSession(event.data.token);
    };

    window.addEventListener('message', handler, false);

    return () => window.removeEventListener('message', handler);
  }, []);

  const renderContentReadyScreen = () => {
    return <Reader key={'reader'} token={token} storyId={storyId} uri={uri} />;
  };

  const renderInitialized = () => {
    return (
      <div className="tw-flex tw-h-screen tw-items-center tw-justify-center">
        <Spinner intent={Intent.NONE} size={50} />
      </div>
    );
  };

  const renderConfigured = () => {
    return (
      <div className="tw-flex tw-h-screen tw-items-center tw-justify-center">
        <Spinner intent={Intent.PRIMARY} size={50} />
      </div>
    );
  };

  const renderLoading = () => {
    return <div className="tw-h-screen tw-overflow-hidden tw-items-center tw-justify-center" />;
  };

  return (
    <div className="tw-h-screen">
      {mode === Mode.Initialized && renderInitialized()}
      {mode === Mode.Configured && renderConfigured()}
      {mode === Mode.Loading && renderLoading()}
      {mode === Mode.ContentReady && renderContentReadyScreen()}
    </div>
  );
};

export default App;
