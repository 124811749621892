import '@blueprintjs/core/lib/css/blueprint.css';
import { ChevronUpIcon } from '@heroicons/react/solid';
import 'normalize.css/normalize.css';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { configuration } from '../../services/configuration';
import { ExternalUser } from '../../services/models/external-user';
import { SharedLink } from '../../services/models/shared-link';
import { StoryTweet } from '../../services/models/story-tweet';
import { getSharedLink } from '../../services/shared-links';

function classNames(...classes: (false | null | undefined | string)[]): string {
  return classes.filter(Boolean).join(' ');
}

interface ReaderProps {
  uri: string;
  storyId: number;
  token: string;
}

const Reader: FunctionComponent<ReaderProps> = ({ uri, storyId, token }) => {
  const [sharedLink, setSharedLink] = useState<SharedLink>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getTweets = async () => {
      try {
        const result = await getSharedLink(storyId, token);
        setSharedLink(result);
      } catch (error) {
        setSharedLink(undefined);
      }
    };

    getTweets();
  }, [storyId, token]);

  if (!token || !storyId || !uri) {
    return null;
  }

  function onDismiss() {
    setOpen(false);
  }

  return (
    <div className="tw-flex tw-flex-col tw-h-full tw-divide-y tw-divide-gray-200 tw-overflow-hidden ">
      <div className="tw-min-h-0 tw-flex-1 tw-flex tw-flex-col tw-overflow-y-scroll">
        <div className="tw-relative tw-flex-1 ">
          <iframe
            title="Newslit Reader"
            src={`${configuration.backend.baseUrl}/storytext?url=${uri}`}
            className="tw-w-full tw-h-full tw-border-none"
          />
        </div>
      </div>

      {sharedLink && sharedLink.tweets.length > 0 && (
        <>
          <div className="tw-flex-shrink-0 tw-flex tw-justify-start tw-overflow-y-scroll">
            <div
              className="tw-flex tw-cursor-pointer tw-justify-between tw-w-full tw-px-4 tw-py-4 tw-bg-white hover:tw-bg-transparent tw-text-gray-600 hover:tw-text-gray-900 tw-items-center tw-text-sm tw-font-medium focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-white"
              onClick={() => setOpen(true)}
            >
              <ShareBar
                shareCount={sharedLink?.shareCount}
                externalUsers={sharedLink?.externalUsers}
              />
            </div>
          </div>
          <BottomSheet
            open={open}
            onDismiss={onDismiss}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ maxHeight }) => [
              maxHeight - maxHeight / 10,
              maxHeight / 4,
              maxHeight * 0.6,
            ]}
            expandOnContentDrag={true}
          >
            <div className="tw-relative">
              <ul className="tw-divide-y tw-divide-gray-200">
                {sharedLink?.tweets?.map((tweet, index) => (
                  <li key={index} className="tw-relative tw-py-4 hover:tw-bg-gray-50">
                    <TweetItem tweet={tweet} />
                  </li>
                ))}
              </ul>
            </div>
          </BottomSheet>
        </>
      )}
    </div>
  );
};

export default Reader;

interface TweetItemProps {
  tweet: StoryTweet;
}

function TweetItem({ tweet }: TweetItemProps) {
  if (!tweet) return null;

  return (
    <a
      href={tweet.externalUrl}
      target="_blank"
      rel="noreferrer"
      className="tw-block hover:tw-bg-gray-50 hover:tw-no-underline focus:tw-outline-none"
    >
      <div className="tw-flex tw-space-x-3 tw-px-4">
        <img className="tw-h-10 tw-w-10 tw-rounded-full" src={tweet.imageUrl} alt="" />
        <div className="tw-flex-1 tw-space-y-1">
          <div className="tw-flex-1">
            <div className="tw-flex tw-items-center tw-justify-start">
              <div className="tw-text-sm tw-font-medium tw-text-gray-900 tw-overflow-hidden">
                {tweet.name}
              </div>
              <div className="tw-ml-1 tw-text-sm tw-font-normal tw-text-gray-500 tw-truncate">
                @{tweet.username}
              </div>
            </div>
            <div className="tw-flex tw-whitespace-nowrap tw-text-xs tw-text-gray-500">
              <time dateTime={tweet.dateCreated?.toString()} className="tw-whitespace-nowrap">
                Commented {tweet.time}
              </time>
            </div>
          </div>
          <div className="tw-mt-1">
            <p className="tw-text-sm tw-text-gray-700">
              <span
                dangerouslySetInnerHTML={{
                  __html: tweet.bodyHtml.replace(
                    /href/g,
                    "target='_blank' class='tw-underline' href"
                  ),
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </a>
  );
}

interface ShareBarProps {
  shareCount: number;
  externalUsers: ExternalUser[];
}

function ShareBar({ shareCount, externalUsers }: ShareBarProps) {
  return (
    <>
      <span>
        <span className="tw-inline-block tw-whitespace-nowrap tw-text-center tw-rounded tw-align-middle tw-mr-2 tw-font-bold tw-text-gray-700 tw-bg-gray-200 tw-text-base tw-py-1 tw-px-2 share-count">
          {shareCount}
        </span>
        {externalUsers?.map((externalUser, index) => {
          const isLast = index === externalUsers.length - 1;

          return (
            <img
              key={index}
              src={externalUser.imageUrl}
              alt={externalUser.username}
              className={classNames(
                !isLast ? 'tw-mr-n1' : '',
                'tw-inline tw-w-7 tw-h-7 tw-rounded-full tw-ring-2 tw-ring-white'
              )}
              loading="lazy"
            />
          );
        })}
      </span>
      <ChevronUpIcon className="tw-h-6 tw-w-6" />
    </>
  );
}
