import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { configuration } from './configuration';

export const getGraphQLClient = (): ApolloClient<any> => {
  return new ApolloClient({
    uri: configuration.backend.graphqlUrl,
    cache: new InMemoryCache(),
  });
};

export const getAuthenticatedGraphQLClientByToken = (token: string): ApolloClient<any> => {
  const headers: { [header: string]: string } = {
    authorization: `Bearer ${token}`,
  };

  return new ApolloClient({
    headers,
    uri: configuration.backend.graphqlUrl,
    cache: new InMemoryCache(),
  });
};
