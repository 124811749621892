export const configuration = {
  cedApiKey: "8b4L6NW8oIuMKCfFsH42gyPZni7DxiFPcupsmDZmiW1PKdn2Alp7QkibabFnIejKngz4SctPRe9v2dJOXf0mrYIhkh8Cq6cBu6se",
  environment: "sandbox",
  version: "1.0.33",
  backend: {
    graphqlUrl: "https://sandbox.newslithq.com/graphql",
    graphiqlUrl: "https://sandbox.newslithq.com/_ah/graphql",
    externalLoginUrl: "https://sandbox.newslithq.com/auth/external",
    baseUrl: "https://sandbox.newslithq.com",
  },
  launchdarkly: {
    clientSideId: "5f68ae03f294fc0b5bfc5f62",
    sdkKey: "sdk-3b5c9356-67c9-407e-a796-d98f18a0387e",
  },
  newslitUikit: {
    registry: "https://registry.npmjs.org/",
  },
  spa: {
    url: "https://app-sandbox.newslithq.com/",
    hostingUrl: "https://newslit-sandbox.web.app",
  },
};