import gql from 'graphql-tag';
import { EmptyResponseError } from './errors/empty-response-error';
import { getGraphQLClient } from './graphql-client';
import { Session } from './models/session';

export const loginVerification = async (token: string): Promise<Session> => {
  const client = getGraphQLClient();

  const response = await client.mutate<{ accountLoginVerification: Session }>({
    mutation: _getLoginVerificationMutation(),
    variables: {
      token,
    },
  });

  if (!response.data || !response.data.accountLoginVerification) {
    throw new EmptyResponseError();
  }

  return response.data.accountLoginVerification;
};

const _getLoginVerificationMutation = () => {
  return gql`
    mutation accountLoginVerification($token: String!) {
      accountLoginVerification(input: { token: $token }) {
        account {
          userid
        }
        token
      }
    }
  `;
};
